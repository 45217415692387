import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {IdentificationService} from "../../services/identification.service";

@Component({
  selector: 'app-middleware',
  templateUrl: './middleware.component.html',
  styleUrls: ['./middleware.component.scss']
})
export class MiddlewareComponent implements OnInit {

  state: string | undefined;
  code: string | undefined;
  operationId: string | undefined; 
  loading: boolean = false;
  alertMessage: string = '';
  alertType: 'success' | 'info' | 'warning' | 'danger' = 'info';
  countdown: number = 3

  constructor(
    private route: ActivatedRoute,
    private identificationService: IdentificationService) {
  }

  ngOnInit(): void {
    this.state = this.route.snapshot.queryParams["state"];
    this.code = this.route.snapshot.queryParams["code"];
    this.setAlertMessage();
    this.doCallback();
  }

  setAlertMessage(): void {
    if (this.state && this.code) {
      this.alertMessage = 'Te estamos redirigido para gestionar los consentimientos.';
      this.alertType = 'info';
    } else if (this.state) {
      this.alertMessage = 'Estamos Redigiendote al final de la operación';
      this.alertType = 'success';
    } else {
      this.alertMessage = 'Petición erronea no se puede continuar con el proceso. Cierra la pestaña, si persiste el error póngase en contacto con  la  empresa con la que ha iniciado el proceso de identificación.';
      this.alertType = 'danger';
    }
  }

  doCallback() {
    this.loading = true;
    const params: { [key: string]: string } = {};
    if (this.code) params["code"] = this.code;
    if (this.state) params["state"] = this.state;
  
    this.identificationService.middlewareCallback(params).subscribe({
      next: stepResponse => {
        console.log(stepResponse);
        if (stepResponse.redirect) {
          window.location.replace(stepResponse.redirectUrl);
        }
      },
      error: err => {
        console.error('Error during middleware callback:', err);
      }
    }).add(() => this.loading = false);
  }

}
