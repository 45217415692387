import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Constants} from '../../shared/constants.shared';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SessionService} from 'src/app/services/session.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {IdentificationService} from '../../services/identification.service';
import {NotificationUtils} from '../../utils/notifications.utils';
import {VerifyOtpRes} from '../../interfaces/responses/verify-otp-res.interface';

@Component({
  selector: 'verify-phone-page',
  templateUrl: './verify-phone.component.html'
})
export class VerifyPhoneComponent implements OnInit{

  _loading: boolean = false;

  userIdentityDocumentType: string | null = null;
  userIdentityDocument: string | null = null;

  form: FormGroup = this.formBuilder.group({
    otp: ["", [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern("^[0-9]*$")
    ]]
  });

  @ViewChild("otpInput", { static: false }) otpInput: any;

  get valid() { return this.form.valid; }

  get otp() { return this.form.controls["otp"].value; }

  get phone() { return this.session.phone; }
  get country() { return this.session.country; }

  get title() {
    const phone = `${this.country ? this.country.phoneCode : ''} ${this.phone}`;
    return this.translate.instant("title.code_sent_to").replace(Constants.LOCALISE_REPLACE_STR, phone);
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  set otp(value: string) { this.form.controls["otp"].setValue(value); }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private session: SessionService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private identification: IdentificationService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.sendOtp();
    this.getInputData();
  }

  getInputData() {
    this.route.queryParams.subscribe(params => {
      this.userIdentityDocumentType = params['user_identity_document_type'];
      this.userIdentityDocument = params['user_identity_document'];
    });
  }

  sendOtp() {

    this.identification.sendOtp().subscribe({
      next: res => {

        if (!res || (res && !res.sent)) return;
        this.session.verifiedPhone = false;

      }, error: _ => this.session.error = Constants.ErrorTemplate.ERROR_GENERIC
    }).add(() => this._loading = false);

  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     if(this.otpInput) this.otpInput.nativeElement.focus();
  //   });
  // }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(_: any) {
    return false;
  }

  submit(event: any) {

    if (event) event.preventDefault();

    if (!this.form.valid) return;

    const countryId = this.session.country!.id;
    const phone = this.session.phone!;
    const otp = this.form.controls["otp"].value;

    this._loading = true;

    this.identification.verifyOtp(countryId, phone, otp).subscribe({
      next: (res: VerifyOtpRes) => this.handleVerifyRes(res),
      error: err => this.identification.handleVerifyCodeResError(err),
    }).add(() => this._loading = false);

  }

  resendOtp() {

    this._loading = true;

    this.identification.sendOtp().subscribe({
      next: res => {

        this._loading = false;

        if (!res) return;

        if (res.sent) {
          this.snackbar.open(this.translate.instant("title.resent_otp"),
            this.translate.instant("actions.accept"), NotificationUtils.snackbarConfig())
        }

      },
    });

  }

  handleVerifyRes(res: VerifyOtpRes | undefined) {
    const currentQueryParams = this.route.snapshot.queryParams;

    const queryParams = {
      ...this.session.operationIdQueryParam(),
      user_identity_document_type: currentQueryParams['user_identity_document_type'],
      user_identity_document: currentQueryParams['user_identity_document']
    };

    if (res && res.correct) {
      this.session.verifiedPhone = true;
      this.router.navigate([Constants.Path.IDENTITY_DOC], 
        { queryParams: 
          queryParams
       });
    }
  }

  back() {
    history.back();
  }

}
