<form [formGroup]="form" *ngIf="_countries" class="page d-flex flex-column justify-content-between h-100 scrollable" style="overflow: auto;">

    <section class="align-items-end">
        <yoid-language-selector style="width: 40%; float: right;"></yoid-language-selector>
    </section>

    <section class="d-flex align-items-start">

        <div *ngIf="logo" class="logo-container my-4">
            <img [src]="logo" alt="Logo" class="logo">
        </div>

    </section>

    <section>

        <h2 translate class="mb-2">title.identify_with_bank</h2>
        <p translate>title.type_phone</p>

        <section class="d-flex align-items-center gap-lg-4 gap-2">

            <div class="d-flex flex-column">
                <mat-form-field (click)="toggle()" style="width: 100px;">
                    <input #countryToggle type="text" [value]="_country ? _country.phoneCode : ''" [disabled]="_loading"
                        style="text-align: left !important; cursor: pointer;" class="country-toggle" matInput readonly>
                </mat-form-field>
            </div>

            <mat-form-field class="w-100">
                <input #phoneInput formControlName="phone" name="phone" type="phone"
                    [placeholder]="'header.phone_number' | translate" autocomplete="off" inputmode="tel" matInput>
                <button *ngIf="form.controls['phone'].value.length > 0" [disabled]="_loading" matSuffix mat-icon-button
                    aria-label="Clear" (click)="clearPhone()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

        </section>

        <div style="margin-top: -10px;">
            <powered-by />
        </div>

        <!-- <country-select [countries]="_countries" (onSelect)="select($event)" class="w-100" /> -->

    </section>

    <section>

        <div class="d-flex align-items-center gap-lg-3">
            <mat-checkbox formControlName="tcChecked" [indeterminate]="false" (change)="validate()"/>
            <span [innerHTML]="termsAndConditions"></span>
        </div>

        <div class="d-flex align-items-center gap-lg-3 mb-4">
            <mat-checkbox formControlName="ppChecked" [indeterminate]="false" (change)="validate()"/>
            <span [innerHTML]="privacyPolicy"></span>
        </div>

        <yoid-button [disabled]="!_valid" [loading]="_loading" (onSubmit)="submit()"
            [text]="'actions.next' | translate"></yoid-button>

    </section>

</form>
