import {Error} from "../interfaces/error.interface";

export class Constants {

    public static LOCALISE_REPLACE_STR: string = "%VAR%";

    public static SNACKBAR_DURATION = 3000;

    public static DEFAULT_TIME_TO_RETRY_OTP = 60;

    static Path = class {

        public static ROOT = "";
        public static PHONE = "phone";
        public static FINALIZE_APP = "finalize-app";
        public static VERIFY_PHONE = "verify-phone";
        public static IDENTITY_DOC = "identity-doc";
        public static SELECT_BANK = "select-bank";
        public static PERMISSIONS = "permissions";
        public static MIDDLEWARE = "middleware";
        public static SIGNATURE_STEPS = "signature-steps";
        public static DOCUMENT = "document";
        public static OPERATION = "operation";
        public static IDENTIFICATION = "identification";
        public static CONSENTS = "consents";
        public static SIGNATURE = "signature";

    }

    static Session = class {

        public static TOKEN = "token";

        public static LAST_OTP_SENT = "lastOtpSent";
        public static LAST_OTP_VERIFIED = "lastOtpVerified";

    }

    static IdDocType = class {

        public static DNI_ID = 1;
        public static NIE_ID = 2;

    }

    static DialogIcon = class {

        public static ERROR = "assets/img/icon/ic_error.svg";
        public static OK = "assets/img/ok.svg";

    }

    static ErrorTemplate = class {

        public static ERROR_NOT_FOUND: Error = {
            img: "assets/img/error.svg",
            title: "title.page_not_found_title",
            description: "title.page_not_found_description",
        }

        public static ERROR_IFRAME: Error = {
            img: "assets/img/error.svg",
            title: "title.error_iframe_title",
            description: "title.error_iframe_description",
        }

        public static ERROR_NO_OPERATION: Error = {
            img: "assets/img/error.svg",
            title: "title.error_no_operation_title",
            description: "title.error_no_operation_description",
        }

        public static ERROR_GENERIC: Error = {
            img: "assets/img/error.svg",
            title: "title.error_try_later_title",
            description: "title.error_try_later_description",
        }

    }

}
