import {NgModule} from '@angular/core';
import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';

import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatRippleModule} from '@angular/material/core';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';

import {VerifyPhoneComponent} from './verify-phone/verify-phone.component';
import {PhoneComponent} from './phone/phone.component';
import {IddocComponent} from './iddoc/iddoc.component';
import {SelectBankComponent} from './select-bank/select-bank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {PermissionsComponent} from './permissions/permissions.component';
import {IdentificationComponent} from './operations/identification/identification.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ErrorComponent} from './error/error.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {HttpClientXsrfModule} from '@angular/common/http';
import {SignStepsComponent} from './sign-steps/sign-steps.component';
import {DocumentComponent} from './document/document.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {DocumentDialogComponent} from './document/document-dialog/document-dialog.component';
import {SignatureComponent} from './operations/signature/signature.component';
import {FinalizeAppComponent} from './finalize-app/finalize-app.component';
import {MiddlewareComponent} from './middleware/middleware.component';

const DECLARATIONS = [
  PhoneComponent,
  VerifyPhoneComponent,
  IddocComponent,
  SelectBankComponent,
  PermissionsComponent,
  IdentificationComponent,
  SignatureComponent,
  ErrorComponent,
  NotFoundComponent,
  SignStepsComponent,
  DocumentComponent,
  DocumentDialogComponent,
  SignStepsComponent,
  FinalizeAppComponent
];

const IMPORTS = [
  HttpClientXsrfModule,
  SharedModule,
  MatSelectModule,
  MatSnackBarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatRippleModule,
  PdfViewerModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  TranslateModule.forChild({
    extend: true
  }),
];

@NgModule({
  declarations: [ ...DECLARATIONS, MiddlewareComponent ],
  imports: [
    ...IMPORTS,
    FormsModule,
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [...DECLARATIONS],
  providers: [ Location, { provide: LocationStrategy, useClass: PathLocationStrategy } ]
})
export class PagesModule { }
