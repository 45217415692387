import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

  @Input() type: 'success' | 'info' | 'warning' | 'danger' = 'info';
  @Input() dismissible: boolean = false;
  @Output() closed = new EventEmitter<void>();

  closeAlert() {
    this.closed.emit();
  }
}
